@import url(https://use.typekit.net/mpw5dkx.css);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;1,600;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.card {
    margin: 0px 15px ;
    border-radius:10px;
    background:"#F7F7F7";
    width: 94%;
    border: "1px solid #FFB532";
  }

.MuiTypography-root{
    font-size: 10px;
}

.disabled_row{
  opacity: 0.4;
}

.dropdownIcon{
  color:#FFFFFF 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.dropdownIcon:hover{
		color: white;
    cursor: pointer;
    opacity: 1;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCCCC;
  border-radius: 5px; 
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

.MuiDialog-paper{
    border-radius: 10px !important;
}

.card_alert {
    height: 98vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiDialog-paperWidthSm {
    width: 500px !important;
    border-radius: 12px !important;
    background: #F6F6F6 0% 0% no-repeat padding-box !important;
}

.modalTitle {
    text-align: center;
    font-family: muli, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    color: #025BA5;
    opacity: 1;
}

.MuiButton-root {
    color: white !important;
    padding: 6px 16px !important;
    font-size: 0.875rem !important;
    min-width: 64px !important;
    box-sizing: border-box !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    font-family: Muli, Arial, sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.75;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    text-align: center !important;
    /*background: #16B1F3 0% 0% no-repeat padding-box !important;*/
}

.MuiDialogActions-root {
    flex: 0 0 auto !important;
    display: flex !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 20px;
    flex-direction: column !important;
}

.contentTextField {
    display: flex;
    justify-content: center;
    align-items: center;
}

.listEmpresa {
    width: 100% !important;
    height: 360px;
}

.MuiPaper-elevation0 {
    box-shadow: none;
    background: #F6F6F6 !important;
}

.messageEnterprise {
    color: #025BA5;
    padding-top: 14px;
}

hr {
    border: 1px solid #DADADA;
    background-color: #DADADA;
}



.login {
    height: 98vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recaptcha{
    margin: 12px 9px;
}

/* DoughnutChart.css */
.custom-legend {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.custom-legend ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.custom-legend li {
  font: 12px 'Roboto';
  white-space: nowrap;
  cursor: pointer;
  color: #575756;
  display: flex;
  align-items: center;
}

.custom-legend li.striked {
  text-decoration: line-through;
}

.custom-legend li.striked span {
  background: #b3b3b3;
}

.custom-legend span {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.custom-legend li.selected {
  text-decoration: line-through;
}

.custom-legend-left {
  display: inline !important;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 60px !important;
  padding-bottom: 20px !important;
  margin-right: 10px !important;
  /* min-width: 160px; */
}

.custom-legend-left li {
  margin-bottom: 10px;
}

.doughnut {
  display: flex; 
  min-Width: 450px
}

.doughnut div.canva {
  max-Width: 269px;
  max-Height: 300px;
  cursor: pointer;
}

/* .responseTimeData canvas{
  /* max-Height: 550px; 
  max-Height: 300px;
}

.responseTimeData{
  /* max-Height: 550px; 
  height: 300px;
} */

/* .transactionData {
  overflow-x: auto;
  width: 600px;
  position: relative;
}

.transactionData canvas{
  left: 0;
  top: 0;
  pointer-events: none;
  position: absolute;
} */

.transactionData {
  position: relative;
}

.transactionData > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.transactionDataArea {
  /* overflow-x: scroll; */
  height: 350px;
}

.responseTimeData {
  position: relative;
}

.responseTimeData > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.responseTimeDataArea {
  overflow-x: scroll; 
  height: 600px;
}

.chart-actions {
  margin: 16px 0
}
.chart-action {
  transition: background .25s,border-color .25s;
  background: rgba(40,44,52,.05);
  border: 1px solid transparent;
  border-radius: 6px;
  color: #3080d0;
  text-decoration: none!important;
  display: inline-block;
  font-size: .8rem;
  padding: 8px 16px;
  margin: 0 8px 8px 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none
}
.sign-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 440px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
  ;
}

.sign-paper-man {
  position: absolute;
  display: 'flex';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  min-height: 440px;
  background: #FFFFFF;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.5718113780021667px solid #E3AC31;
  border-radius: 10px;
  opacity: 1;
  padding: 0 0;
}

.sign-paper-man-center {
  position: relative;
  padding: 7px 50px;
  top: 19px !important;
  background: transparent !important;
}

.tabs-manuscrita {
  width: 110%;
  padding-top: 0px !important;
}
.message {
  padding-left: 20px !important;
  padding-right: 20px;
  padding-top: 72px;
  width: 100% !important;
  text-align: center;
  font: normal normal normal 18px/22px Muli;
  letter-spacing : 0px;
  color: #9D9D9C;
  opacity: 1
}
.signs {
  position: relative;
  font-weight: bold;
  justify-items: right !important;
  font-size: 22px;
  top: -2px !important;
  background: transparent !important;
  text-align: center !important;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.tabs-manuscrita .Mui-selected {
  background: transparent !important;
  text-align: center;
  letter-spacing: 0px;
  color: #E55200 !important;
  opacity: 1;
}

.sign-button-option-sel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 0px #00000033;
  border: 0.6078676581382751px solid #F5AE19;
  border-radius: 7px;
  opacity: 1;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 0px;
  color: #F5AE19;
  opacity: 1;
  font-size: 18px;
}

.title-sign-draw {
  text-align: left;
  letter-spacing: 0px;
  color: #E55200;
  opacity: 1;
  font-size: 19px;
}

.sig-pad {
  width: 480px;
  height: 250px;
  background: transparent;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.sign-buttons {
  width: 100%;
  height: 30px;
}

.sign-buttons-default {
  font-size: 9pt;
  color: #ff9800;
  height: 30px;
}

.sig-image {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.sig-image {
  width: 100%;
  height: 100%;
}

.btnResponsive {
  display: none !important;
}

.draw-sign {
  position: absolute;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: normal normal normal 18px/22px Muli;
  color: #9D9D9C;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .sign-paper-man {
    min-height: 240px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }
  .message {
    padding-left: 7px !important;
    padding-right: 10px !important;margin-top: -10px !important;
    width: 2% !important;
    text-align: center;
    font: normal normal normal 18px/22px Muli;
    letter-spacing : 0px;
    color: #9D9D9C;
    opacity: 1
  }
  .signs {
    top: 0px !important;
  }
  #box-icons-buttons{
    margin-top:-12px;
    margin-right: 7px !important;
  }
  .tabs-manuscrita {
    width: 109%;
    padding-top: 0px !important;
  }
  .sign-paper-man-center {
    padding: 11px 20px 0 20px;
    top: 1px !important;
  }
  .sig-pad {
    width: calc(100%);
  }
  .load-sign {
    width: calc(100%);
  }
  .drag-sign {
    width: calc(100%);
    margin-bottom: -5px !important;
  }
  .drag-sign img {
    width: calc(90%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000000;
    box-shadow: none;
  }
  .textZoneUploadSign {
    display: none !important;
  }
  .predefined-alert {
    display: none !important;
  }
  .btnResponsive {
    display: flex !important;
  }
  .sig-pad {
    width: calc(100%);
  }
  #signature-canvas {
    width: calc(100%);
    height: auto;
  }
  #selected {
    top: 6px;
    width: 100%;
  }
}
body {
  font-family: "adobe-clean", sans-serif;
}

.dragme {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 270px;
  height: 203px;
  cursor: move;
}




.container-sign {
  position: absolute;
  background: #EDEDED 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 1px solid #ff415a;
}

.resizer {
  width: 12px;
  height: 12px;
  position: absolute;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABJQTFRFAAAAgICAgICAgICAgICAgICAtO8NbwAAAAZ0Uk5TAGD/MO+gAsrfdgAAACJJREFUeJxjYAABRjDJIAThKEI4AigcZgjHBMJxhXACsHIAWY8Df2W6/88AAAAASUVORK5CYII=);
  border: none;
}

.resizer.nw {
  top: -2.5px;
  left: -2.5px;
  cursor: nw-resize;
}

.resizer.ne {
  top: -2.5px;
  right: -2.5px;
  cursor: ne-resize;
}

.resizer.sw {
  bottom: -2.5px;
  left: -2.5px;
  cursor: sw-resize;
}

.resizer.se {
  bottom: 2px;
  right: 2px;
  cursor: se-resize;
}

.container-sign canvas {
  height: calc(100%);
  width: calc(100%);
}

.container-sign button {
  position: absolute;
  border-radius: 50%;
  padding: 0.5em;
  width: 21px;
  height: 21px;
  cursor: pointer;
  outline: none;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAXCAYAAADk3wSdAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFaADAAQAAAABAAAAFwAAAABRE2NUAAADc0lEQVQ4Eb2UTWwTRxTH/zNeG5sQEmNVdisCh0RtUEhEDkRKoqhR1KqVWglo6CWq4FJ64UTg0EMPgQMSnLghLjSXShABElRuKVGQHOpEfMVEBAkKpZBULgR/xPZ6vV7vzjCzyMZfkZJLnzQ7M2/e/PY/M2+GYBXLXPMPEQM9xO0fADc7ifuDPDcSBuHWHNcTMxlNvRw4oC7Xm07qObPBzp+VwBdDpLF1G93cDtrUDuIOgGWegqUfg6081K1oMMEL2RMNX82fq8co+dKXfO3qtU9i5uKEytdg+ciPqWxw5x98Ao4SpLwhgbnQnigvpNeAex9SiE7y7PXeuXJWqS0VrhdYRJsvL6pqsPN8CSYbcg/XuuQiqLrOhUdeq5f9n9ng3BX/kH7/6GJ1kOxb8XucG8mKISs5z7keq/DZHVbguRsDCxJKLYYe2tjWYv+h7MNis9BvDYvyLVBYsUdY/A706W+QC30tfOmyaNEkChh1fZi5FOigMg9l2lQbbe4C9e4CSz2ywSKFoIdHAEuD0jIMODdXT4HD270RhO+kMrFlHtaY0gB33y+gzZ2wUgvI3/7BBjo/PgznjmM14dLh8Ha5iatxN+Wej4TaQN0gCLCz9XsQUDHOhbomKG0CvoqRTa2gLp+fMiPrkDelnlnR35GfGxVDHMTlE/uYsveUa//WCwdLRlDIxZ9SnfiW5dWrNms5hPwdoYpbUFoPwfP5tL0VXP1bgPfVHpQAGIkFqM6OOGUF46aZfFTNhEPspVyOBLq6jgMuL9z9F2yw0rK37kFJcV791lUsXf+uLTX5ZW3eSY+xUutf5RpbiTn+5sZwsqTuyc2Tmhb5qRawDk86uJvf/fPXsxIqjxWxDZ+ezsSewfxvSnbXbdq9UUQ37odBtlQ+gzPh6VBscj/P/zOxDn2ca3eP8OfTp3g4HB4rqrGVyg6hzoN/NYymlp9NQZ05JE7dLMbUrWX6ZH7rwaIawCtlINTf3z9WDKx4+WdnZweFzPFN+fntW60pKCQPpWkHlC0doCIT2Mo8jPhD+/XP0a144TkIizaEPB7P3u7u7ncPhCBXQOWfIpFIs6Zp44SQPW5zEW5zCR5rCU5qQmeNyCkt0JXtKDh8KRE+1tfXd0bOK7caaHFQqmaMDQr4oPDtEqVJlJdiJQ9E/YBSOt7b2/tCtP8fewswmxHT7m8CcQAAAABJRU5ErkJggg==) 0% 0% no-repeat padding-box;
  border: none;
  top: -10px;
  right: -10px;
}

[data-title]:hover:after {
  border-radius: 3px;
  opacity: 1;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #706f6f;
  color: #FFF;
  font: normal normal normal 11px Muli;
  position: absolute;
  padding: 3px 6px 3px 6px;
  top: -2.5em;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}




.drag-container {
  position: relative;
  text-align: center;
  overflow: auto;
  border: 1px solid;
  border-radius: 5px;
  background-color: #ededed;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

#draggable {
  background-color: #ccc;
  border: 1px solid #000;
  box-shadow: 0 2px 4px 0 rgba(156, 148, 148, 0.5);
}

.pdf-container-workarea {
  padding-top: 0px !important;
}

.pdf-botonera-firmas {
  padding-top: 0px;
  width: 90%;
  /* max-height: 60px; */
}
.gridBotonera {
  grid-gap: 7px;
  flex-direction: row;
}
.pdf-botonera-btn {
  background: #ededed 0% 0% no-repeat padding-box;
  padding-top: 7px;
  border: 1px solid #989898;
  font: normal normal 100 14px/14px Muli;
  color: #4F4F4F !important;
  border-radius: 10px;
  opacity: 1;
  min-height: 42px;
  margin: 0px 7px 0px 7px;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  width: auto;
  cursor: pointer;
}

.pdf-botonera-btn-active {
  background: #F5E9D0 0% 0% no-repeat padding-box;
  padding-top:7px;
  border: 1px solid #ff415a;
  font: normal normal normal 14px/14px Muli;
  border-radius: 10px;
  opacity: 1;
  min-height: 42px;
  margin: 0px 7px 0px 7px;
  padding-left: 0px;
  padding-right: 0px;
  width: auto;
  color: #4F4F4F;
  cursor: pointer;
}

.pdf-btn {
  background: #ff415a !important;
  margin-top: 32px !important;
  font: normal normal 800 18px/22px Muli !important;
  min-width: 118px !important;
  box-sizing: border-box;
  text-transform: none !important;
  height: 42px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF !important;
}

.pdf-btn-default {
  color: #000000 !important;
  font-size: 11pt !important;
  font-weight: 600 !important;
  text-transform: none !important;
  height: 60px !important;
}

.pdf-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 95%;
  border: 1px solid #ff415a !important;
  margin-top: 0px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
}

.pdf-container {
  height: 100%;
  padding: 20px;
  width: 100%;
}

.btn-close-sign-popup {
  padding: 0px !important;
  margin-top: -15px !important;
  margin-right: -15px !important;
  width: 20px !important;
  height: 20px !important;
}

.btn-close-sign-popup svg {
  width: 20px !important;
  height: 20px !important;
}

#btn-recipient-close{
  margin-top: 8px !important;
  margin-right: 8px !important;
  background-size: 90% !important;
  height: 2px !important;
}

.btn-close-sign-modal {
  background-image: url(/static/media/icono-cerrar.af8951e6.svg);
  background-position: 0 0 !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
  width: 24px !important;
  height: 26px !important;
  margin-top: 4px !important;
  margin-right: 4px !important;
}

#forwardCode{
  margin-top:7.5px !important;
  margin-left:10.5px !important;
}

.btn-forward-sign-modal {
  background-image: url(/static/media/icon_forward.33ebbd5d.svg);
  background-position: 0 0 !important;
  background-size: 60% !important;
  background-repeat: no-repeat;
  width: 24px !important;
  height: 26px !important;
  margin-top: 4px !important;
  margin-left: 5px !important;
}

.subtitleOptions{
  font: normal normal 600 16px/22px Muli !important;
  font-weight:500 !important;
  letter-spacing: 0px;
  color: #4F4F4F !important;
  opacity: 1;
}

.pdf-miniaturas {
  height: 100%;
  margin-bottom: 0;
  background-color: #ededed;
  border-radius: 5px;
  border: #dadada 2px solid;
  border-top: 0px;
}

.pdf-miniaturas-firmas {
  margin-top: 7px;
  overflow-y: auto;
  height: 92%;
  background-color: #ededed;
}

.pdf-canvas-mini {
  display: block;
  border: 1px solid;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pdf-canvas-mini-p {
  text-align: center;
}

.pdf-canvas {
  display: block;
  margin: 15px auto 15px auto;
  border: 1px solid;
  border-color: #ccc;
  /*overflow:auto;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.div-canvas {
  position: relative;
  display: inline-block;
  /*overflow: auto;*/
}

.accordion {
  cursor: pointer;
  margin: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  border: 0px !important;
  color: #4f4f4f;
  background-color: #c6c6c6;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%234F4F4F%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
}

.accordion-firmas {
  position: relative;
  /*background-color: #eee;*/
  color: #444;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.accordion-firmas-img {
  width: 50px;
  background-color: white;
  height: auto;
  padding: 5px;
  border: 1px solid;
  border-color: #ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.accordion-firmas-img-close {
  cursor: pointer;
  top: 50%;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  font-size: 20pt;
}

.accordion-firmas-img-text {
  top: 50%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.accordion-firmas-img-close:hover {
  color: red;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active,
.accordion:hover {
  margin: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-width: 0px !important;
  border: 0px #706f6f solid !important;
  width: 80%;
  padding: 5px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #706f6f;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
}

/* Style the accordion panel. Note: hidden by default */

.panel {
  padding: 0 18px;
  /*background-color: white;*/
  display: none;
  overflow: hidden;
}

#loadingPDF {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(/static/media/loader.3c02dc5b.gif);
  /*url("https://i.stack.imgur.com/MnyxU.gif");*/
  background-repeat: no-repeat;
  background-position: center;
}

.pdf-alert {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 5px 5px #00000080;
  border: 1px solid lightgray;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
}

.pdf-alert-title {
  text-align: center;
  letter-spacing: 0px;
  color: #ff415a;
  opacity: 1;
  font-size: 19px;
}

.zoom-button {
  border: 0px;
  border-right: 1px solid #9d9d9c;
  padding-right: 5px !important;
}

.addDocumentToSign {
  background: #ededed;
  padding: 0px !important;
  /*border: #DADADA 2px solid;*/
  border-bottom: 0px !important;
  margin-bottom: -5px !important;
}

.addDocumentToSign button {
  background: #ffffff;
  padding: 0px !important;
  margin-top: 5px;
}

.mini-container {
  height: 100%;
  width: 20%;
}

.botonera-container {
  width: 80%;
  height: 100%;
  padding-left: 15px;
}

.box-botonera {
  display: flex;
  padding-top:11px;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.pdf-botonera-siguiente {
  width: 10%;
  height: 2%;
}

.pdf-btn-float {
  background: #ff415a !important;
  padding: 20px !important;
  color: #ffffff !important;
  font: bold 17px Muli !important;
  text-transform: none !important;
  text-shadow: 2px 3px 0px #00000040 !important;
  width: 160px !important;
  height: 49px !important;
  border-radius: 7px !important;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 2px 0px #00000040;
  visibility: hidden;
}

.editar-container {
  height: 20px;
  width: 100% !important;
  padding: 0px;
}

.box-signer {
  display: none !important;
  position: fixed;
  visibility: visible;
  top: 35%;
  right: 0;
  transform: translate(-55%, 0);
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  background: #ff415a !important;
  align-content: center;
  box-shadow: 0px 4px 5px #0000004d !important;
}

.box-signer button {
  display: flex;
  border: none;
  overflow: hidden;
  width: 100%;
  height: auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAATCAYAAAD4f6+NAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAJKADAAQAAAABAAAAEwAAAAAax6VQAAACg0lEQVRIDcWUTUhUURTH56mEiWStNAlFQcSVhJmLKAaDwBbpQgwNUolooYtx0cYgmgrCaCG2KFBwURCKgiIi4SJIJXIhgqDIVE6UWqik4wetev7+43MahkGv49cffpx7zzvn3PPuu++6XIck27afwBT0Q84hLWNWlgaeg/QdVmEC0k2yEwj0EFhqEhwlZtyyrIZwP/UuML8NY1AJ7ZAPqTAHO4sCyxCzIqtT6I1T7B7WC6o/BImRsdHmCTi1QzXRHhr4esJjWDSL+RXwwQ24BKvQwE7+xR6daOYENEK4PjLJ2EsX2qGYxELKPQdpUAZFkAvSNHRCEzuzLoepLAqrWDWc3iFpnGdeius86BAXwBnQJ8oDaQGSYAluETuMNRI14whMJieghpaZpBhkqpFJeO/E+rEB+Aqf4A+0wjcYgW4W6MUGxTpnGfzCZzuubb+ugxY4CTe17c3ght3UQzE/hfVLz8JvgW/RWazDKZCNFeX472MH4QEUwmd8d8j5x9jFuATzGHRV9MkF+xNFM8AH0hrUgZqZh2mYAWlhy9iXsafgFejSVE4zxHyeQ29AETXzA1RY0g5osUyYBEnPnsIorEM9DIKkhq+HCjLQTV2GNT7U28nk6dy9A9lRKIYxPkeAZxWM80B3UBNMgAd0Xp+BBV3gIV6f/79INr2pVTAk8l6C1AoDoK0Pvi32BWzAXSVgrzpzjD0CVaFCEQP9ZY/wuSP80aY1vI1fD8g5jxmAn+CFNvDDNWJWeB7P+CJj/X1B4atlsAIf8OuPPDhRvA2kh/A2ONp6sX0vErfXCiyuy0+3sy7CRXDDF3gNRy8a0mfucnZFZgZ0oI9PNKCmdKjV2IE2swluFUpXts+IOAAAAABJRU5ErkJggg==) no-repeat center
    center;
}

.bar-sign {
  display: none !important;
  display: flex;
  flex-direction: row;
  color: #ff415a;
}

.bar-sign button {
  padding: 0;
  height: 24px;
  width: auto;
  margin: 15px 15px 0px 15px;
}

.bar-sign h3 {
  margin: 15px 5px 0px 10px;
}

.nameQR::before {
  content: "Código ";
}

.view-container {
  height: 75% !important;
}

@supports (object-fit: cover) {
  .box-signer button {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media screen and (max-width: 1252px) {
  .pdf-paper {
    width: 100%;
    height: 100%;
    border: none !important;
    margin-top: 0px !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
  }
  .nameQR::before {
    content: "";
  }
  .pdf-container {
    padding: 0;
  }
  .mini-container {
    display: none !important;
  }
  .botonera-container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 0;
  }
  .pdf-botonera-firmas {
    width: 100%;
  }
  .pdf-botonera-siguiente {
    display: none !important;
  }
  .editar-container {
    display: none !important;
  }
  .drag-container {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .subtitleOptions{
    display: none;
  }
  .pdf-btn-float {
    position: fixed;
    visibility: visible;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .box-botonera {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .gridBotonera {
    grid-gap:0px;
    flex-direction: column;
  }
  .pdf-botonera-btn {
    width: 112px;
    padding-top: 10px;
    height: 60.19px;
    font-size: 11px;
  }
  .pdf-botonera-btn-active {
    width: 112px;
    padding-top: 10px;
    height: 60.19px;
    font-size: 11px;
  }
  .pdf-canvas {
    width: 100%;
    margin-top: 0;
  }
  .box-signer {
    display: block !important;
  }
  .bar-sign {
    display: flex !important;
    margin: 0;
  }
  .view-container {
    margin-top: 18px !important;
    height: 65% !important;
  }
  .pdf-botonera-firmas {
    max-height: 115px !important;
  }
}

@media screen and (max-width: 991px) {
  .pdf-botonera-firmas {
    padding-top: 5px;
    height: auto !important;
  }
  .pdf-botonera-btn-active {
    margin-left: auto;
    margin-right: auto;
  }
  .pdf-botonera-btn {
    margin-left: auto;
    margin-right: auto;
  }
}

.sign-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 314px;
  min-height: 335px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 14px;
  opacity: 1;
  text-align: center;
  overflow-y: auto;;
}


.dv_resultado
{
   width: 100%;
   background-color: #FFFFFF;
}
.title-sign-draw
{
  text-align: left;
  letter-spacing: 0px;
  color: #E55200;
  opacity: 1;
  font-size: 22px;
}
.button-common-orange-fy
{
    background: #F5AE19 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px #00000040;
    opacity: 1;
    width: 90%;
    height: 45px;
}

.form-control-pop-up
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033;
    border: 0.8387856483459473px solid #706F6F;
    border-radius: 10px;
    width: 100%;
    height: 33px;
    opacity: 1;
}
.title-background {
  margin: 0;
  color: white;
  height: 100px;
  padding-bottom: 90px !important;
  padding-top: 20px;
}

.zone-upload-container {
  max-width: 1024px;
  margin-top: 20px !important;
}

#paper-upload-result {
  padding-top: 35px !important;
  padding: 25px;
}

.paper-upload {
  padding: 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ff415a;
}

.contentZoneUpload {
  border-style: dashed!important;
  border: 2px dashed #9D9D9C;
  border-color: #9D9D9C;
  border-width: 3px !important;
  border-radius: 10px!important;
}

.sign-paper-source-signature {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  min-height: 321px;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center !important;
  justify-content: center !important;
  overflow-y: auto;
  outline: none;
}

.btnGeneral18 {
  background-color: #ff415a !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 38px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  margin-top: 14px !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneral18:disabled, button[disabled]{
  opacity: 0.19;
}

.titlePopUpForwardMail {
  text-align: center;
  margin: 14px 0px 9px!important;
  font: normal normal bold 18px/21px Muli !important;
  line-height: 30px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #e55200 !important;
  opacity: 1;
}

.opionCustom {
  width: 237px;
  white-space:normal !important;
  text-transform:unset !important;
}

.opionCustom:hover {
  background-color: #706F6F !important;
  color: #FFF !important;
}

.certificateText{
  padding-left: 4%;
  padding-top: 4px;
  color: #9D9D9C;
}

.selectCustom {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  max-width: 80% !important;
  padding: 0px !important;
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
}

.selectCustom div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.selectCustom .MuiInput-underline {
  margin: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  color: #706F6F !important;
}

.selectCustom label {
  display: inline;
}

.selectCustom label {
  margin: 0px !important;
  margin-top: -20px !important;
  padding: 0px !important;
  height: 10px !important;
}

.selectCustom option {
  width: 237px;
  max-width: 237px !important;
}

.selectCustom option:hover {
  background-color: #706F6F;
  color: #FFF;
}

.selectQR {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  max-width: 82% !important;
  margin-top: 2px !important;
  opacity: 1;
}

.selectQR div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.over-list {
  border-radius: 10px;
  border: 1px solid #B2B2B2;
  background-color: #FFFF;
}

.btn-close-popup {
  position: absolute;
  padding: 0px !important;
  float: right;
  top: 2px;
  right: 2px;
  width: 20px !important;
  height: 20px !important;
  display: none !important;
}

.btn-close-popup svg {
  width: 20px !important;
  height: 20px !important;
}

.dialog-container {
  width: 450px;
  max-height: 300px;
  margin: 20px;
  display: 'flex';
  flex-direction: 'row';
  overflow-y: scroll;
}

.tableDocumentSigned {
  width: 100%;
}

.headerTableDocumentSigned {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  opacity: 1;
  text-align: center;
  font: normal normal bold 11px/16px Muli;
  letter-spacing: 0px;
  color: #E55200;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 3px !important;
}

.headerTableDocumentSigned tr{
  padding: 7px !important;
}

.headerTableDocumentSigned tr th, .headerTableDocumentSigned tr {
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 3px !important;
}

.headerTableDocumentSigned tr {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
}

.headerTableDocumentSigned tr th:first-child {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.headerTableDocumentSigned tr th:last-child {
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.space-between {
  display: none !important;
}


.checkbox-label input:checked~.seleccionada {
  background-color: lawngreen;
  border-radius: 5px;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  border: 3px solid #98C500;
  transform: rotate(0deg) scale(0);
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
}

.checkbox-label input:checked~.checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  right: 6px;
  top: 2.5px;
  width: 6px;
  height: 11px;
  border: solid #98C500;
  border-width: 0 3px 3px 0;
  background-color: transparent;
}

.drag-sign {
  display: block;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.load-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.drag-sign img {
  box-shadow: 0px 5px 10px #68666680;
}

.drag-sign p {
  text-align: center;
  font: normal normal normal 12px/24px Muli;
  letter-spacing: 0px;
  color: #E55200;
}

.sign-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 75px;
}

.sign-container img {
  height: calc(80%);
}

.firma-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 47px;
}

.firma-container img {
  height: calc(100%);
  width: 100px
}

.show-loading {
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url(/static/media/loader.3c02dc5b.gif);
  background-position: center center;
  background-repeat: no-repeat;
}

.container-sign {
  position: absolute;
  background: #EDEDED 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 1px solid #ff415a;
  /*height: 50px;
  width: 50px;
  top: 30px;
  left: 30px;*/
}

.resizer {
  width: 12px;
  height: 12px;
  /*border-radius: 50%;
  background: white;
  border: 1px solid #878787;*/
  position: absolute;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABJQTFRFAAAAgICAgICAgICAgICAgICAtO8NbwAAAAZ0Uk5TAGD/MO+gAsrfdgAAACJJREFUeJxjYAABRjDJIAThKEI4AigcZgjHBMJxhXACsHIAWY8Df2W6/88AAAAASUVORK5CYII=);
  border: none;
}

.resizer.nw {
  top: -2.5px;
  left: -2.5px;
  cursor: nw-resize;
}

.resizer.ne {
  top: -2.5px;
  right: -2.5px;
  cursor: ne-resize;
}

.resizer.sw {
  bottom: -2.5px;
  left: -2.5px;
  cursor: sw-resize;
}

.resizer.se {
  bottom: 2px;
  right: 2px;
  cursor: se-resize;
}

.container-sign canvas {
  height: calc(100%);
  width: calc(100%);
  /*border-radius: 12px;
  border: 2px solid #878787;*/
}

.container-sign button {
  position: absolute;
  border-radius: 50%;
  padding: 0.5em;
  width: 21px;
  height: 21px;
  /*border: 2px solid #ff415a;
  background-color: #FFFFFF;
  color: #ff415a;*/
  cursor: pointer;
  outline: none;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAXCAYAAADk3wSdAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFaADAAQAAAABAAAAFwAAAABRE2NUAAADc0lEQVQ4Eb2UTWwTRxTH/zNeG5sQEmNVdisCh0RtUEhEDkRKoqhR1KqVWglo6CWq4FJ64UTg0EMPgQMSnLghLjSXShABElRuKVGQHOpEfMVEBAkKpZBULgR/xPZ6vV7vzjCzyMZfkZJLnzQ7M2/e/PY/M2+GYBXLXPMPEQM9xO0fADc7ifuDPDcSBuHWHNcTMxlNvRw4oC7Xm07qObPBzp+VwBdDpLF1G93cDtrUDuIOgGWegqUfg6081K1oMMEL2RMNX82fq8co+dKXfO3qtU9i5uKEytdg+ciPqWxw5x98Ao4SpLwhgbnQnigvpNeAex9SiE7y7PXeuXJWqS0VrhdYRJsvL6pqsPN8CSYbcg/XuuQiqLrOhUdeq5f9n9ng3BX/kH7/6GJ1kOxb8XucG8mKISs5z7keq/DZHVbguRsDCxJKLYYe2tjWYv+h7MNis9BvDYvyLVBYsUdY/A706W+QC30tfOmyaNEkChh1fZi5FOigMg9l2lQbbe4C9e4CSz2ywSKFoIdHAEuD0jIMODdXT4HD270RhO+kMrFlHtaY0gB33y+gzZ2wUgvI3/7BBjo/PgznjmM14dLh8Ha5iatxN+Wej4TaQN0gCLCz9XsQUDHOhbomKG0CvoqRTa2gLp+fMiPrkDelnlnR35GfGxVDHMTlE/uYsveUa//WCwdLRlDIxZ9SnfiW5dWrNms5hPwdoYpbUFoPwfP5tL0VXP1bgPfVHpQAGIkFqM6OOGUF46aZfFTNhEPspVyOBLq6jgMuL9z9F2yw0rK37kFJcV791lUsXf+uLTX5ZW3eSY+xUutf5RpbiTn+5sZwsqTuyc2Tmhb5qRawDk86uJvf/fPXsxIqjxWxDZ+ezsSewfxvSnbXbdq9UUQ37odBtlQ+gzPh6VBscj/P/zOxDn2ca3eP8OfTp3g4HB4rqrGVyg6hzoN/NYymlp9NQZ05JE7dLMbUrWX6ZH7rwaIawCtlINTf3z9WDKx4+WdnZweFzPFN+fntW60pKCQPpWkHlC0doCIT2Mo8jPhD+/XP0a144TkIizaEPB7P3u7u7ncPhCBXQOWfIpFIs6Zp44SQPW5zEW5zCR5rCU5qQmeNyCkt0JXtKDh8KRE+1tfXd0bOK7caaHFQqmaMDQr4oPDtEqVJlJdiJQ9E/YBSOt7b2/tCtP8fewswmxHT7m8CcQAAAABJRU5ErkJggg==) 0% 0% no-repeat padding-box;
  border: none;
  top: -10px;
  right: -10px;
}

[data-title]:hover:after {
  border-radius: 3px;
  opacity: 1;
  /*transition: all 0.1s ease 0.5s;*/
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #706f6f;
  color: #FFF;
  font: normal normal normal 11px Muli;
  /*font-size: 50%;*/
  position: absolute;
  padding: 3px 6px 3px 6px;
  top: -2.5em;
  /*bottom: -1.6em;*/
  /*left: 100%;*/
  white-space: nowrap;
  /*box-shadow: 1px 1px 3px #222222;*/
  opacity: 0;
  /*border: 1px solid #111111;*/
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.menu-tabs {
  position: relative;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF99 100%) 0% 0% no-repeat padding-box;
  background-color: #FFFFFF;
  opacity: 1;
  height: 80vh;
  width: 20%;
}

.tab-panel {
  width: 80%;
}

.Mui-selected {
  /*background: rgb(237, 237, 237);
  background: transparent linear-gradient(180deg, #DADADA00 1%, #DADADA 100%) 0% 0% no-repeat padding-box;
  border-bottom: 3px solid #E55200 !important;
  color: #E55200 !important;*/
  color: #E55200 !important;
}

.verticalTabsItems {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #6D6E71;
  background-color: #fff;
  opacity: 1;
}

.verticalTabsItems:hover {
  color: #E55200 !important;
  font-weight: bold !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.btnGeneralSign:disabled, button[disabled] {
  opacity: 0.4;
}

.btnGeneralSignIcon {
  background-color: #ff415a !important;
  margin-top: 10px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 19px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneralSign {
  background-color: #ff415a !important;
  margin-top: 10px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneralSign:disabled, button[disabled] {
  opacity: 0.4;
}

.textZoneUpload {
  width: 100%;
  margin: 8px 0 8px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.textZoneUpload span {
  color: #E55200;
  font: normal normal bold 18px/22px Muli;
  letter-spacing: 0px;
}

.btnGeneral {
  background-color: #ff415a !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneral:disabled, button[disabled] {
  opacity: 0.4;
}

.btnGeneralResize {
  background-color: #ff415a !important;
  margin-bottom: 49px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-left: 5px !important;
}

.btnPopup {
  background-color: #ff415a !important;
  letter-spacing: 0.8px;
  margin-top: 7px !important;
  margin-bottom: 29px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  height: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

.base-style-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px !important;
  padding: 20px;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  background-color: #EDEDED;
  border: 2px dashed;
  cursor: pointer;
  box-sizing: border-box;
}

@media screen and (max-width: 505px){
  .btnGeneralSign {
    font-size: 16px !important;
    height: 47px !important;
    letter-spacing: 1px;
    border-radius: 9px !important;
    width: 219px;
  }
  .btnGeneralSignIcon {
    font-size: 16px !important;
    padding-left: 22px !important;
    height: 47px !important;
    letter-spacing: 1px;
    padding-right: 0px !important;
    border-radius: 9px !important;
    width: 219px;
  }
}

@media screen and (max-width: 765px) {
  .bodyMiddle {
    background:none;
  }
}

@media screen and (max-width: 991px) {
  .paper-upload {
    width: 100%;
    padding: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .btn-close-popup {
    display: inline !important;
    background-image: url(/static/media/icono-cerrar.af8951e6.svg);
    background-position: 0 0 !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
    margin: 5px !important;
    width: 24px;
    height: 26px;
  }
  .space-between {
    display: block !important;
  }
  .over-list {
    border: 0.5px solid #E3AC31 !important;
    border-radius: 5px;
  }
  .MuiBox-root, .MuiBox-root-111 {
    padding-top: 7px !important;
  }
}

@media screen and (max-width: 1250px) {
  .dialog-container {
    width: 100%;
    position: relative;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 330px;
    overflow-y: scroll;
  }
  .tableDocumentSigned {
    border-collapse: separate;
    border-spacing: 10px 0;
  }
  .tableDocumentSigned td {
    padding: 15px 0;
  }
  .tableDocumentSigned tr {
    border-bottom: 1px solid #D6D6D6;
    border-top: 1px solid #D6D6D6;
  }
  .tableDocumentSigned tr>* {
    width: 80vw;
    display: inline;
    border-bottom: none;
    text-transform: capitalize;
    padding: 10px 0 0 0;
    text-align: left;
  }
  .tableDocumentSigned tr td:first-child {
    display: inline-block;
    font-weight: bold;
    padding-bottom: 2px;
    width: 100% !important;
  }
  .headerTableDocumentSigned {
    display: none !important;
  }
}
.MuiDialog-paperWidthSm {
    width: 500px !important;
    border-radius: 12px !important;
    background: #F6F6F6 0% 0% no-repeat padding-box !important;
}

.modalTitle {
    text-align: center;
    font-family: muli, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    color: #025BA5;
    opacity: 1;
}

.MuiButton-root {
    color: white !important;
    padding: 6px 16px !important;
    font-size: 0.875rem !important;
    min-width: 64px !important;
    box-sizing: border-box !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    font-family: Muli, Arial, sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.75;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    text-align: center !important;
    /*background: #16B1F3 0% 0% no-repeat padding-box !important;*/
}

.MuiDialogActions-root {
    flex: 0 0 auto !important;
    display: flex !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 20px;
    flex-direction: column !important;
}

.contentTextField {
    display: flex;
    justify-content: center;
    align-items: center;
}

.listEmpresa {
    width: 100% !important;
    height: 360px;
}

.MuiPaper-elevation0 {
    box-shadow: none;
    background: #F6F6F6 !important;
}

.messageEnterprise {
    color: #025BA5;
    padding-top: 14px;
}

hr {
    border: 1px solid #DADADA;
    background-color: #DADADA;
}


.calculator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.recaptcha{
    margin: 12px 9px;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

@media(min-width: 700px) {
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;
    }
}

@media(min-width: 250px) {
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;
    }
}
